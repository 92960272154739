const state = {
  currencies: [],
}
const getters = {
  currenciesGetter: ({ currencies }) => currencies,
}

const mutations = {
  setCurrencies: (state, payload) => (state.currencies = payload),
}

const actions = {
  async getCurrencies({ commit }) {
    const res = await this.$axios.get('currencies')
    commit('setCurrencies', res.data.result.items)
  },
}
export default { state, getters, mutations, actions }
