const state = {
  agencies: [],
  branches: [],
  agents: [],
}

const getters = {
  agenciesGetter: ({ agencies }) => agencies,
  branchesGetter: ({ branches }) => branches,
  agentsGetter: ({ agents }) => agents,
}

const mutations = {
  setAgencies: (state, payload) => (state.agencies = payload),
  setBranches: (state, payload) => (state.branches = payload),
  setAgents: (state, payload) => (state.agents = payload),
}

const actions = {
  async getAgencies({ commit }) {
    const res = await this.$axios.get('agencies')
    commit('setAgencies', res.data.data)
  },
  async getBranches({ commit }, id) {
    const res = await this.$axios.get(`agencies/${id}/branches`)
    commit('setBranches', res.data.data)
  },
  async getAgents({ commit }, { agency_id, branch_id }) {
    const res = await this.$axios.get(`agencies/${agency_id}/branches/${branch_id}`)
    commit('setAgents', res.data.data)
  },
}
export default { state, getters, mutations, actions }
